/////////////////////////////
// MEDIA QUERIES
/////////////////////////////

@mixin tiny-break {
  @media (max-width: #{$tiny-width}) {
    @content;
  }
}

@mixin small-break {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mid-break {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin large-break {
  @media (max-width: #{$large-width}) {
    @content;
  }
}

@mixin laptop-break {
  @media (max-width: #{$laptop-width}) {
    @content;
  }
}

@mixin frame-break {
  @media (max-width: #{$frame-width}) {
    @content;
  }
}


/////////////////////////////
// HEIGHT MEDIA QUERIES
/////////////////////////////

@mixin short-screen-break {
  @media (max-height: 880px) {
    @content;
  }
}
