@import '../../../scss/_styles.scss';

.modal-container {
	transform: translateZ(0);
	transition: all $trans-time $trans-curve;
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
  z-index: -1;
  background: rgba(black, .75);

  > * {
    visibility: hidden;
    opacity: 0;
    transition: $trans-curve $trans-time;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &.is-open {
      visibility: visible;
      opacity: 1;
    }
  }

  &.open {
		opacity: 1;
		visibility: visible;
    z-index: 39;
	}
}
