//////////////////////////////
// BASELINE GRID
//////////////////////////////

$body-font-size : 18px;
$baseline-px : 40px;
$baseline : ($baseline-px / $body-font-size)+rem; // This computes to 30px in rem

@function base($multiplier) {
  @return ($baseline-px / $body-font-size) * $multiplier+rem;
}

/////////////////////////////
// BREAKPOINTS
/////////////////////////////
$tiny-width : 480px;
$mobile-width : 600px;
$tablet-width : 850px;
$mobile-height : 400px;
$tablet-height : 700px;
$desktop-height : 900px;
$laptop-width : 1280px;
$large-width : 1024px;
$frame-width : 1680px;

//////////////////////////////
// FONTS
//////////////////////////////

$body-font : 'DIN-web',
Impact,
sans-serif;

//////////////////////////////
// MISC SIZES
//////////////////////////////

$radius-sm: 0px;

//////////////////////////////
// COLORS
//////////////////////////////

$barely-white : rgba(white, .15);
$trans-white : rgba(white, .6);

$dark-gray : #222222;
$dark-gray-trans : rgba($dark-gray, .6);

$blue : #3594ff;
$dark-blue : #145C9D;

//////////////////////////////
// TRANSITIONS
//////////////////////////////

$trans-time : 200ms;
$trans-curve : cubic-bezier(1.000, 0.255, 0.240, 0.870);

//////////////////////////////
// STYLE
//////////////////////////////

$stroke-width: 3px;

%shadow {
  box-shadow: 0 30px 60px -20px rgba(0, 0, 0, .12);
}

%textShadow {
  text-shadow: 0 14px 14px rgba(black, .1);
}

//////////////////////////////
// UTILITIES
//////////////////////////////

@mixin color-svg($color) {
  .stroke {
    stroke: $color;
  }

  .fill {
    fill: $color;
  }
}

@mixin clearfix {

  &:before,
  &:after {
    content: "";
    display: block;
  }

  &:after {
    clear: both;
  }
}

@mixin btn-reset {
  text-align: left;
  border: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: block;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &:focus {
    outline: none;
  }
}

@mixin fadeDelay($count, $delay) {
  @for $i from 1 through $count {
    >*:nth-child(#{$i}) {
      transition-delay: $i * 30ms + $delay;
    }
  }
}
