@import '_structure';
@import '_queries';

/////////////////////////////
// GRID
/////////////////////////////

// Gutter
$gutter : base(.5);
$cols   : 12;

/////////////////////////////
// ROWS
/////////////////////////////

@mixin row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -#{$gutter};
	margin-left: -#{$gutter};
}

/////////////////////////////
// COLUMN SETUP
/////////////////////////////

@mixin gutter {
	margin-left: $gutter;
	margin-right: $gutter;
}

@mixin col($col) {
	@include gutter;
	width: calc(#{$col / $cols * 100}% - #{base(1.1)});
}

@mixin max-col($col) {
	@include gutter;
	max-width: calc(#{$col / $cols * 100}% - #{base(1.1)});
}

@mixin push($col) {
	margin-right: $gutter;
	margin-left: calc(#{$col / $cols * 100}% + #{base(.49)});
}

/////////////////////////////
// GRID BREAKS
/////////////////////////////

@mixin col-break-large {
	@include large-break {
		width: calc(50% - #{base(1)});
	}
}

@mixin col-break-mid {
	@include mid-break {
		width: calc(100% - #{base(1)});
		margin-left: base(.5);
		margin-right: base(.5);
	}
}

@mixin col-break-mid-half {
	@include mid-break {
		width: calc(50% - #{base(1)});
	}
}

@mixin col-break-small {
	@include small-break {
		width: calc(100% - #{base(1)});
		margin-left: base(.5);
		margin-right: base(.5);
	}
}

@mixin col-break-small-half {
	@include small-break {
		width: calc(50% - #{base(1)});
	}
}
