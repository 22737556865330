/////////////////////////////
// Add font declarations here
/////////////////////////////

@font-face {
	font-family: 'DIN-web';
	src: url('/fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/DINPro-Light.woff') format('woff'),
		 url('/fonts/DINPro-Light.ttf') format('truetype'),
		 url('/fonts/DINPro-Light.svg#DINPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'DIN-web';
	src: url('/fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/DINPro-Medium.woff') format('woff'),
		 url('/fonts/DINPro-Medium.ttf') format('truetype'),
		 url('/fonts/DINPro-Medium.svg#DINPro-Medium') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'DIN-web';
	src: url('/fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/DINPro-Bold.woff') format('woff'),
		 url('/fonts/DINPro-Bold.ttf') format('truetype'),
		 url('/fonts/DINPro-Bold.svg#DINPro-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'DIN-web';
	src: url('/fonts/DINPro-Black.eot?#iefix') format('embedded-opentype'),
		 url('/fonts/DINPro-Black.woff') format('woff'),
		 url('/fonts/DINPro-Black.ttf') format('truetype'),
		 url('/fonts/DINPro-Black.svg#DINPro-Black') format('svg');
	font-weight: 900;
	font-style: normal;
}
