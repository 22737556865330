/////////////////////////////
// Z-INDEX CHART
/////////////////////////////

$bg-z: 10;
$page-z: 20;
$footer-z: 30;
$modal-mask-z: 40;
$modal-content-z: 50;
$header-z: 60;

