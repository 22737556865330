@import '_vars';
@import '_structure';

/////////////////////////////
// HEADINGS
/////////////////////////////

%h1 {
	margin: 0;
	@include margin(1);
	text-transform: uppercase;	
	font-size: base(1.375);
	line-height: base(1.5);
	font-weight: 900;
	letter-spacing: 3px;

	@include small-break {
		font-size: base(1.125);
		line-height: 1;
	}
}

%h2 {
	text-transform: uppercase;
	margin: 0 0 $baseline;
	font-size: base(1.125);
	line-height: 1;
	font-weight: 900;
	letter-spacing: 2px;

	@include small-break {
		font-size: base(.875);
		line-height: 1;
	}
}

%h3 {
	text-transform: uppercase;
	margin: 0 0 base(.75);
	font-size: base(.875);
	line-height: $baseline;
	font-weight: 900;
	letter-spacing: 2px;

	@include small-break {
		font-size: base(.625);
		line-height: base(.75);
	}
}

%h4 {
	text-transform: uppercase;
	margin: 0 0 $baseline;
	font-size: base(.75);
	line-height: $baseline;
	font-weight: 600;
	letter-spacing: 1px;

	@include small-break {
		font-size: base(.675);
		line-height: base(.75);
	}
}

%h5 {
	text-transform: uppercase;
	margin: 0 0 base(.5);
	font-size: base(.5);
	line-height: base(.75);
	font-weight: 600;
}

%small {
	margin: 0;
	font-size: base(.45);
	line-height: base(.75);
}

/////////////////////////////
// TYPE STYLES
/////////////////////////////

%label {
	text-transform: uppercase;
	font-size: base(.4);
	letter-spacing: 1px;
	font-family: $body-font;
	-webkit-font-smoothing: auto;
}

%label-small {
	text-transform: uppercase;
	font-size: base(.325);
	letter-spacing: 1px;
	font-family: $body-font;
	-webkit-font-smoothing: auto;
}

%small-body {
	font-size: base(.65);
	line-height: base(.75);
}

%large-body {
	font-size: base(.675);

	@include mid-break {
		font-size: base(.5);
		line-height: $baseline;
	}
}
