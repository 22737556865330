img.aligncenter {
	margin: 0 auto;
}

.alignleft {
	float: left;
	padding-right: base(1);
}

.alignright {
	float: right;
	padding-left: base(1);
}

.wp-caption-text {
	font-size: base(.38);
	padding-top: base(.1);
	line-height: 1.5;
	font-style: italic;
}


@include mid-break {
	.wp-caption {
		max-width: 100%;
		width: unset !important;
	}
	.alignright,
	.alignleft {
		margin-left: auto;
		margin-right: auto;
		float: none;
		padding-right: 0;
		padding-left: 0;
	}
}