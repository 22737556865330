@import 'fonts.scss';
@import 'animations.scss';
@import '_styles';
@import '_wp';

/////////////////////////////
// GLOBAL DEFAULTS
/////////////////////////////

* {
	box-sizing: border-box;
}

html, body, #root {
	margin: 0;
}

html {
	background: black;
	font-size: $body-font-size;
	line-height: $baseline-px;
	position: relative;

	@include mid-break {
		font-size: 14px;
		line-height: 30px;
	}
}

body {
	overflow-x: hidden;
	font-family: $body-font;
	font-weight: 300;
	color: white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	max-width: 100%;
	height: auto;
	display: block;
}

::selection {
	background: $blue;
	color: white;
}

::-moz-selection {
	background: $blue;
	color: white;
}

.row {
	@include row;
}

.col {
	@include gutter;
}

h1 {
	@extend %h1;
}

h2 {
    @extend %h2;
}

h3 {
    @extend %h3;
}

h4 {
    @extend %h4;
}

h5 {
	@extend %h5;
}

p {
    margin: 0 0 $baseline;

    @include small-break {
    	margin: 0 0 base(.5);
    }
}

ul {
	padding-left: $baseline;
    margin: 0 0 $baseline;
}

a {
	text-decoration: none;
	color: $blue;
	cursor: pointer;
}

hr {
	border: 0;
	border-top: 1px solid $barely-white;
	margin: base(1.5) 0;

	@include mid-break {
		margin: base(1) 0;
	}
}

.large-body {
	@extend %large-body;
}

label {
	@extend %label;
}

/////////////////////////////
// STRUCTURE
/////////////////////////////

.site-wrap {
	display: flex;
	position: relative;
	z-index: $page-z;

	.main-wrap {
		will-change: transform;
		transition: transform $trans-time;
		flex-grow: 1;
	}

	&.sidebar-open {
		.main-wrap {
			transform: translate3d(#{base(5)}, 0, 0);
		}
	}
}

